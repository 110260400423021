<template>
  <main>
    <payment-loading v-if="isLoading"/>
    <div v-else>
      <error-data v-if="isErrorDataSet"/>
    </div>
  </main>
</template>

<script>
import PaymentLoading from '@/base/components/Payment/Loader/Loading'
import ErrorData from '@/base/components/Error/Data'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { DateClass } from '@/base/class/date.class'
import viewMixin from '@/base/mixins/view.mixin'
import { pageTitle } from '@/base/const/routing.const'

export default {
  name: 'Wait',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().wait
    }
  },
  mixins: [viewMixin],
  components: {
    PaymentLoading,
    ErrorData
  },
  props: {
    idTransRemote: {
      type: String,
      required: true
    },
    apiToken: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapState({
      isLoading: state => state.payment.loading
    }),
    ...mapGetters({
      isErrorDataSet: 'isErrorDataSet'
    })
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA',
      'SET_TIMER_TRX_GET_START',
      'SET_TIMER_TRX_START_LOADED'
    ]),
    ...mapActions([
      'getPaymentWaitData'
    ])
  },
  mounted () {
    const data = {
      idTransRemote: this.idTransRemote,
      apiToken: this.apiToken
    }
    this.SET_TIMER_TRX_GET_START(DateClass.getCurrentTimestamp())
    this.getPaymentWaitData(data)
      .finally(() => {
        this.SET_TIMER_TRX_START_LOADED(DateClass.getCurrentTimestamp())
      })
      .catch(error => {
        // przekierowanie na potykacz PISowy jeśli nie ma danych psd2 dla startu WL z kanałem PISowym
        if (error.message === 'psd2Data') {
          this.$router.push({ name: 'RegulationsApprovalStart', params: { apiToken: this.apiToken } })
          return
        }
        this.SET_ERROR_DATA({
          errorCode: error.body.errorCode,
          errorUrl: error.body.errorUrl
        })
      })
  }
}
</script>
